var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { flat: "" } },
    [
      _c("v-card-title", [
        _c("span", { staticClass: "font-weight-bold primary--text" }, [
          _vm._v("User profile")
        ])
      ]),
      _c(
        "v-card-text",
        [
          _c(
            "v-row",
            { attrs: { align: "center" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "4" } },
                [_c("v-subheader", [_vm._v("Name")])],
                1
              ),
              _c("v-col", { attrs: { cols: "8" } }, [
                _c("span", { staticClass: "primary--text subtitle-2" }, [
                  _vm._v(_vm._s(_vm.userInfo.given_name))
                ])
              ])
            ],
            1
          ),
          _c(
            "v-row",
            { attrs: { align: "center" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "4" } },
                [_c("v-subheader", [_vm._v("Surname")])],
                1
              ),
              _c("v-col", { attrs: { cols: "8" } }, [
                _c("span", { staticClass: "primary--text subtitle-2" }, [
                  _vm._v(_vm._s(_vm.userInfo.family_name))
                ])
              ])
            ],
            1
          ),
          _c(
            "v-row",
            { attrs: { align: "center" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "4" } },
                [_c("v-subheader", [_vm._v("Username")])],
                1
              ),
              _c("v-col", { attrs: { cols: "8" } }, [
                _c("span", { staticClass: "primary--text subtitle-2" }, [
                  _vm._v(_vm._s(_vm.userInfo.username))
                ])
              ])
            ],
            1
          ),
          _c(
            "v-row",
            { attrs: { align: "center" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "4" } },
                [_c("v-subheader", [_vm._v("Email")])],
                1
              ),
              _c("v-col", { attrs: { cols: "8" } }, [
                _c("span", { staticClass: "primary--text subtitle-2" }, [
                  _vm._v(_vm._s(_vm.userInfo.email))
                ])
              ])
            ],
            1
          ),
          _c(
            "v-row",
            { attrs: { align: "center" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "4" } },
                [_c("v-subheader", [_vm._v("User status")])],
                1
              ),
              _c("v-col", { attrs: { cols: "8" } }, [
                _c("span", { staticClass: "primary--text subtitle-2" }, [
                  _vm._v(
                    _vm._s(
                      _vm.userInfo.is_active === true ? "Active" : "Inactive"
                    )
                  )
                ])
              ])
            ],
            1
          ),
          _vm.userInfo.is_sysadmin === true
            ? _c(
                "v-row",
                { attrs: { align: "center" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "4" } },
                    [_c("v-subheader", [_vm._v("System Administrator")])],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "8" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "secondary",
                            to: { name: "admin-overview" },
                            depressed: ""
                          }
                        },
                        [_vm._v("manage")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.userInfo.is_sysadmin !== true
            ? _c(
                "v-row",
                { attrs: { align: "center" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "4" } },
                    [_c("v-subheader", [_vm._v("Delete account")])],
                    1
                  ),
                  _c("v-col", { attrs: { cols: "8" } }, [
                    _c(
                      "div",
                      { staticClass: "d-flex flex-column" },
                      [
                        _c("AccountDeleteDialog"),
                        _c(
                          "span",
                          {
                            staticClass: "caption error--text font-weight-bold"
                          },
                          [
                            _vm._v(
                              " Deleting your account will result in your profile being cleared and user access revoked. "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ])
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }