<template>
    <v-card flat>
        <v-card-title>
            <span class="font-weight-bold primary--text">User profile</span>
        </v-card-title>
        <v-card-text>
            <v-row align="center">
                <v-col cols="4">
                    <v-subheader>Name</v-subheader>
                </v-col>
                <v-col cols="8">
                    <span class="primary--text subtitle-2">{{ userInfo.given_name }}</span>
                </v-col>
            </v-row>
            <v-row align="center">
                <v-col cols="4">
                    <v-subheader>Surname</v-subheader>
                </v-col>
                <v-col cols="8">
                    <span class="primary--text subtitle-2">{{ userInfo.family_name }}</span>
                </v-col>
            </v-row>
            <v-row align="center">
                <v-col cols="4">
                    <v-subheader>Username</v-subheader>
                </v-col>
                <v-col cols="8">
                    <span class="primary--text subtitle-2">{{ userInfo.username }}</span>
                </v-col>
            </v-row>
            <v-row align="center">
                <v-col cols="4">
                    <v-subheader>Email</v-subheader>
                </v-col>
                <v-col cols="8">
                    <span class="primary--text subtitle-2">{{ userInfo.email }}</span>
                </v-col>
            </v-row>
            <v-row align="center">
                <v-col cols="4">
                    <v-subheader>User status</v-subheader>
                </v-col>
                <v-col cols="8">
                    <span class="primary--text subtitle-2">{{ userInfo.is_active === true ? 'Active' : 'Inactive' }}</span>
                </v-col>
            </v-row>
            <v-row v-if="userInfo.is_sysadmin === true" align="center">
                <v-col cols="4">
                    <v-subheader>System Administrator</v-subheader>
                </v-col>
                <v-col cols="8">
                    <v-btn color="secondary" :to="{ name: 'admin-overview' }" depressed>manage</v-btn>
                </v-col>
            </v-row>
            <v-row v-if="userInfo.is_sysadmin !== true" align="center">
                <v-col cols="4">
                    <v-subheader>Delete account</v-subheader>
                </v-col>
                <v-col cols="8">
                    <div class="d-flex flex-column">
                        <AccountDeleteDialog> </AccountDeleteDialog>
                        <span class="caption error--text font-weight-bold">
                            Deleting your account will result in your profile being cleared and user access revoked.
                        </span>
                    </div>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>
<script>
import { mapState } from 'vuex'
const AccountDeleteDialog = () => import('@/components/TheUserDeleteAccount')

export default {
    components: { AccountDeleteDialog },
    computed: {
        ...mapState(['userInfo'])
    }
}
</script>
